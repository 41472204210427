@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  position: relative;
  padding: 10px;
  color: #202C45 !important;
}

.login .logo {
  position: absolute;
  top: 20px;
  right: 20px;
}

@media (min-width: 996px) {
  .login {
    background-image: url(/static/media/bg.3a93c1e1.png);
    background-size: 60%;
    background-repeat: no-repeat;
  }
}

.login h1 {
  margin-bottom: 0;
  font-size: 2.2em;
}

.login .wrapper {
  width: 50%;
  display: flex;
  justify-content: center;
  position: relative;
}

@media (max-width: 995px) {
  .login .wrapper {
    width: 100%;
    padding: 0;
  }
  .login .wrapper .content {
    width: 100%;
    max-width: 380px;
  }
}

.login .wrapper form {
  margin-top: 20px;
}

.login .wrapper .button {
  width: 45%;
}

.login .wrapper * {
  box-sizing: border-box;
}

.login .wrapper .inp {
  position: relative;
  margin: auto;
  width: 380px;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 0px;
}

@media (max-width: 995px) {
  .login .wrapper .inp {
    width: 100%;
  }
}

.login .wrapper .inp .label {
  position: absolute;
  top: 20px;
  left: 12px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
  transform-origin: 0 0;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;
  pointer-events: none;
}

.login .wrapper .inp .focus-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: -1;
  transform: scaleX(0);
  transform-origin: left;
}

.login .wrapper .inp input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  border: 0;
  font-family: inherit;
  padding: 16px 12px 0 12px;
  height: 56px;
  font-size: 16px;
  font-weight: 400;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.3);
  color: #000;
  transition: all 0.15s ease;
}

.login .wrapper .inp input:hover {
  background: rgba(0, 0, 0, 0.04);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.5);
}

.login .wrapper .inp input:not(:-ms-input-placeholder) + .label {
  color: rgba(0, 0, 0, 0.5);
  transform: translate3d(0, -12px, 0) scale(0.75);
}

.login .wrapper .inp input:not(:placeholder-shown) + .label {
  color: rgba(0, 0, 0, 0.5);
  transform: translate3d(0, -12px, 0) scale(0.75);
}

.login .wrapper .inp input:focus {
  background: rgba(0, 0, 0, 0.05);
  outline: none;
  box-shadow: inset 0 -2px 0 #0077FF;
}

.login .wrapper .inp input:focus + .label {
  color: #0077FF;
  transform: translate3d(0, -12px, 0) scale(0.75);
}

.login .wrapper .inp input:focus + .label + .focus-bg {
  transform: scaleX(1);
  transition: all 0.1s ease;
}

.layout {
  background-color: #F2F3F5;
  height: 100%;
  min-height: 100vh;
  color: #202C45 !important;
  padding-bottom: 30px;
}

.layout .adminHeader {
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.layout .adminHeader .title {
  font-size: 2em;
}

.layout .content .wording-content p {
  display: inline !important;
}

.layout .content .wording-content .image {
  display: inline-block !important;
}

.layout .content .wording-content .image > img {
  display: inline-block !important;
}

.layout .content .questionWrapper {
  background-color: white;
  border-radius: 3px;
  border: 1px solid #c4c4c4;
  padding: 10px 20px;
  margin-bottom: 30px;
}

.layout .content input {
  border: none;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #c4c4c4;
}

.layout .content label {
  font-weight: 600;
}

.layout .content .css-yk16xz-control {
  border: 1px solid #c4c4c4 !important;
}

.layout .content .katex-display {
  display: inline;
}

.layout .content .katex-display .katex {
  display: inline;
}

.layout .content .katex-display .katex .katex-html {
  display: inline;
}

.layout .content .ReactTags__selected {
  margin: 5px 0;
}

.layout .content .tag-wrapper {
  background-color: whitesmoke;
  border: 1px solid #c4c4c4;
  padding: 5px;
  border-radius: 3px;
  margin: 10px 10px 10px 0;
  font-size: 0.8em;
  cursor: pointer !important;
}

.layout .content .ReactTags__tagInputField {
  display: inline;
  padding: 6px;
  margin-top: 10px;
}

.layout .content .filters {
  font-size: 0.8em;
}

.alternative-button {
  background-color: #0077FF;
  padding: 4px 10px;
  font-size: 0.7em;
  color: white;
  border: none;
  border-radius: 100px;
  font-weight: 600;
  transition: 0.5s;
}

.alternative-button:hover {
  background-color: #0066da;
}

.alternative-button:focus {
  outline: none;
}

.button {
  background-color: #0077FF;
  color: white;
  font-size: 1.1em;
  text-align: center;
  padding: 7px 20px;
  border: none;
  border-radius: 100px;
  font-weight: 500;
  transition: 0.5s;
}

.button:focus {
  outline: none;
}

.button:hover {
  background-color: #0060ce;
  color: white;
  text-decoration: none;
}

.outlined {
  background-color: white !important;
  border: 2px solid #0077FF;
  color: #0077FF;
}

.outlined:hover {
  opacity: 0.7;
}

.button-danger {
  background-color: #db1010 !important;
}

.button-danger:hover {
  background-color: #ad1010 !important;
}

.button-sm {
  padding: 2px 10px !important;
  font-size: 0.7em !important;
}

.site-button {
  background-color: #2087F5;
  border-radius: 3px;
  padding: 13px 20px;
  color: white !important;
  font-weight: bold;
  border: none;
  font-size: 0.9em;
  transition: 0.5s;
  display: inline-block;
  min-width: 150px;
  text-align: center;
}

@media (max-width: 991px) {
  .site-button {
    margin-bottom: 5px;
  }
}

.site-button .Centered {
  vertical-align: middle;
  display: inline-block;
}

.site-button:disabled {
  opacity: 0.5;
}

.site-button-sm {
  min-width: auto !important;
  padding: 5px !important;
  font-size: 0.8em !important;
  font-weight: 500;
}

.site-button:hover {
  background-color: #1b74d3;
  text-decoration: none;
}

.site-button-dark {
  background-color: #202C45;
}

.site-button-dark:hover {
  background-color: #111929;
}

.site-button-success {
  background-color: #20F527 !important;
}

.site-button-success:hover {
  background-color: #18be1e !important;
}

.site-button-danger {
  background-color: #f52020 !important;
}

.site-button-danger:hover {
  background-color: #cf1818 !important;
}

.site-button-secondary {
  background-color: #81899c;
}

.site-button-secondary:hover {
  background-color: #5c6272;
}

.site-button-info {
  background-color: #17a2b8 !important;
}

.site-button-info:hover {
  background-color: #128a9c !important;
}

.collapsable .collapse-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #dce1e6;
  color: black;
  width: 100%;
  text-align: left;
  border: none;
  margin-bottom: 10px;
}

.collapsable .collapse-button i {
  color: rgba(0, 0, 0, 0.5);
}

.collapsable .collapse-button:focus {
  outline: none;
}

.collapsable .card {
  border: none !important;
  border-radius: 0px !important;
  background-color: #f0f4f7 !important;
  margin-bottom: 20px;
}

.collapsable .card:hover {
  transform: none !important;
  box-shadow: none !important;
}

.cut-button {
  background: none;
  width: 30px;
  height: 30px;
  border-radius: 50px;
  font-size: 15px;
  border: none;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.3);
}

.cut-button:hover {
  color: black;
}

.cut-button:focus {
  outline: none;
}

.text-collapse-button {
  color: #202C45;
  background: none;
  border: none;
  margin-bottom: 10px;
}

.text-collapse-button:hover {
  opacity: 0.6;
}

.text-collapse-button:focus {
  outline: none;
}

.ck-content > p {
  display: inline !important;
}

.ck-content .image {
  display: inline-block !important;
}

.ck-content .image > img {
  display: inline-block !important;
}

/* Site */
.site {
  background-color: #FAFAFA;
  min-height: 100vh;
}

.site .bigTitle {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #2087F5;
  font-size: 3.5em;
  margin-bottom: -5px;
}

.site .textBlue {
  color: #2087F5;
}

/* width */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1b7ce4;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #176eca;
}

.ReactTags__suggestions {
  cursor: default;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 !important;
}

.navbar ul {
  list-style: none;
}

.navbar ul li {
  display: inline-block;
  margin-left: 20px;
}

.navbar .navbar-toggler {
  border: none;
}

.navbar .navbar-toggler:focus {
  outline: none;
}

.navbar .navlinks {
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .navbar .navlinks .nav-item {
    margin-bottom: 20px;
  }
}

.navbar .navlinks a {
  color: #202C45;
  font-weight: 600;
}

.navbar .navlinks a:hover {
  color: #111725;
  text-decoration: none;
}

.admin-nav {
  padding: 30px 0 0 0 !important;
  text-align: right;
  margin-bottom: -20px;
}

.admin-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.admin-nav ul li {
  display: inline-block;
  margin-left: 20px;
}

.admin-nav a {
  color: #202C45;
  font-weight: 600;
}

.admin-nav a:hover {
  color: #111725;
  text-decoration: none;
}

.header {
  background-image: url(/static/media/bg.28b67a54.svg);
  width: 100%;
  height: 350px;
  background-color: #2087F5;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  position: relative;
}

@media (max-width: 767px) {
  .header {
    height: auto;
    padding: 50px 0;
  }
}

.header h1 {
  color: white !important;
  font-weight: bold;
  margin: -12px 0;
  padding: 0;
  font-size: 3em;
  font-family: 'Poppins', sans-serif;
}

@media (max-width: 767px) {
  .header h1 {
    font-size: 2em;
  }
}

.header p {
  margin-top: 25px;
  color: white;
}

.header .image {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 50px;
}

.header .image img {
  height: 400px;
}

@media (max-width: 767px) {
  .header .image {
    display: none;
  }
}

footer {
  padding: 20px 0;
  background-color: #202C45;
  color: white !important;
  position: relative;
}

footer .footer a {
  color: white !important;
}

footer .footer a:hover {
  color: #bebebe;
  text-decoration: none;
}

footer .footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

footer .footer ul li {
  margin: 10px 0;
}

footer .logo {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.section {
  margin: 50px 0 100px 0;
}

.section h1 {
  font-size: 1.3em;
  margin-bottom: 15px;
}

.section .big-title {
  font-size: 2.2em !important;
}

.card {
  background-color: 'white';
  border: 1px solid #EEEEEE !important;
  border-top: 4px solid #2087F5 !important;
  border-radius: 6px !important;
  transition: 0.5s;
  margin-bottom: 10px;
}

.card a:hover {
  text-decoration: none;
}

.card .content {
  padding: 20px 20px 0;
  text-align: center;
}

.card .content .title {
  font-size: 1.1em;
  border-bottom: none !important;
}

.card .content .vagas {
  margin-top: 30px;
}

.card .content .vagas h1 {
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  font-size: 5em;
  color: #2087F5;
  line-height: 0.7em;
}

.card .content .vagas span {
  font-size: 1.2em;
  font-weight: 600;
}

.card .content .salario {
  margin-top: 10px;
}

.card .content .salario span {
  font-size: 0.8em;
}

.card .content .salario h1 {
  font-size: 1.5em;
  color: #2087F5;
}

.card .inscricoes {
  border-top: 1px solid #EEEEEE;
  text-align: center;
  padding: 10px 0;
  color: #2087F5;
}

.card:hover {
  transform: scale(1.03);
  box-shadow: 6px 10px 42px -15px rgba(0, 0, 0, 0.19);
}

.cardNotHover:hover {
  transform: none !important;
  box-shadow: none !important;
}

.notCentered .content {
  text-align: left !important;
}

.contentCard .content {
  margin-bottom: 20px;
}

a {
  color: inherit !important;
}

a:hover {
  text-decoration: none !important;
}

.planFree {
  border-top: 4px solid gray !important;
}

.planFree .titleFree {
  color: gray !important;
}

.planFree .priceGroup h1 {
  color: gray !important;
}

.planFree .site-button {
  background-color: gray;
}

.planCard {
  width: 100%;
  max-width: 300px;
  margin: 0 10px;
}

.planCard .title {
  color: #2087F5 !important;
  margin-bottom: 10px !important;
  font-size: 1.5em !important;
}

.planCard .benefits {
  font-size: 0.9em !important;
}

.planCard .benefits ul {
  list-style: none;
  margin: 10px 0 20px 0;
  padding: 0;
}

.planCard .priceGroup {
  margin-left: auto;
  margin-right: auto;
}

.planCard .priceGroup .only {
  margin-bottom: -10px;
}

.planCard .priceGroup .times {
  margin-top: 3px;
  font-weight: 600;
}

.planCard .priceGroup h1 {
  font-size: 4em;
  font-weight: bold;
  color: #2087F5;
  margin: 0;
}

.planCard .priceGroup .vista {
  text-align: center;
  margin-bottom: 20px;
  font-size: 0.8em;
}

@media (max-width: 767px) {
  .planCard {
    margin: 0 auto 20px auto;
  }
}

.profile {
  margin: 50px 0;
}

.profile a:hover {
  text-decoration: none;
}

.profile .profile-card {
  background-color: white;
  border-radius: 7px;
  border: 1px solid #EEEEEE;
  border-top: 4px solid #2087F5 !important;
}

@media (max-width: 768px) {
  .profile .profile-card {
    margin-bottom: 20px;
  }
}

.profile .profile-card .wrapper {
  align-items: center;
  padding: 20px;
}

.profile .profile-card .wrapper .profileTitle {
  border-bottom: 1px solid #EEEEEE;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.profile .profile-card .wrapper .profileTitle h1 {
  font-size: 1.3em;
  margin: 0;
}

.profile .profile-card .wrapper .content {
  padding: 20px 0 10px 0;
}

.profile .profile-card .photo {
  width: 120px;
  height: 120px;
  border-radius: 120px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.5em;
  color: rgba(0, 0, 0, 0.2);
}

.profile .profile-card .name {
  font-size: 1.2em;
  text-align: center;
  margin-top: 10px;
}

.profile .profile-card .sidebarul {
  list-style: none;
  padding: 0;
  margin-top: 10px;
  margin-left: 0;
}

.profile .profile-card .sidebarul li {
  padding: 10px 0;
  padding-left: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.profile .profile-card .sidebarul a {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.5);
}

.profile .profile-card .sidebarul a:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.02);
  display: block;
  color: #2087F5;
}

.profile .profile-card .sidebarul .selected {
  border-left: 3px solid #2087F5;
  background-color: rgba(0, 0, 0, 0.03);
  color: #2087F5;
  padding-left: 12px;
}

.formTitle {
  font-size: 1.1em;
}

.courseThumb {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  border: 1px solid #eeeeee;
  transition: 0.5s;
  overflow: hidden;
  margin-bottom: 10px;
}

.courseThumb:hover {
  transform: scale(1.05);
  box-shadow: 6px 10px 42px -15px rgba(0, 0, 0, 0.5);
}

.classThumb {
  border-radius: 7px;
  overflow: hidden;
  display: block;
  transition: 0.5s;
}

.classThumb:hover {
  transform: scale(1.05);
  box-shadow: 6px 10px 42px -15px rgba(0, 0, 0, 0.5);
}

.classWatched {
  opacity: 0.4 !important;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.nextClasses {
  margin-top: 30px;
}

.nextClasses h1 {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.course-page .chamada-button {
  padding: 10px;
  color: white !important;
  background-color: #02aa02;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  transition: 0.3s;
  display: block;
  text-align: center;
}

.course-page .chamada-button:hover {
  background-color: #028302;
  text-decoration: none;
}

.course-page .sidebar-wrapper {
  position: relative;
}

.course-page .course-page-card {
  background-color: white;
  border-radius: 7px;
  border: 1px solid #EEEEEE;
  border-top: 4px solid #2087F5 !important;
}

@media (max-width: 768px) {
  .course-page .course-page-card {
    margin-bottom: 20px;
  }
}

.course-page .course-page-card .wrapper {
  align-items: center;
  padding: 20px;
}

.course-page .course-page-card .wrapper h1 {
  font-size: 1.3em;
  margin: 10px 0 0 0;
  text-align: center;
}

.course-page .course-page-card .wrapper .content {
  font-size: 1.2em;
}

.course-page .course-page-card .wrapper .content h1, .course-page .course-page-card .wrapper .content p {
  text-align: left;
}

.course-page .course-page-card .wrapper .content h1 {
  font-weight: bold;
  font-size: 1.6em;
  margin-bottom: 10px;
  margin-top: 30px;
}

.course-page .course-page-card .wrapper .chamada {
  margin-top: 50px;
  text-align: center;
}

.course-page .course-page-card .wrapper .chamada h1, .course-page .course-page-card .wrapper .chamada p {
  text-align: center;
}

.course-page .course-page-card .wrapper .chamada h1 {
  font-size: 2em;
  color: #00ac00;
}

.course-page .course-page-card .wrapper .chamada p {
  margin-top: -5px;
}

.course-page .course-page-card .wrapper .chamada .chamada-button {
  padding: 20px;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
}

.course-page .course-page-card .course-page-valor h1 {
  font-weight: bold;
  color: #00b400;
  font-size: 2em;
  text-align: left;
}

.course-page .course-page-card .course-page-valor p {
  margin-top: 30px;
  margin-bottom: -10px;
}

.question {
  font-size: 1.1em;
  margin-bottom: 20px;
}

.question strong {
  font-weight: 600;
}

.question .stat {
  text-align: center;
  margin-right: 30px;
}

.question .stat h1 {
  font-size: 3em;
  font-weight: bolder;
  margin: 0;
}

.question .q-header {
  border-bottom: 2px solid #dce1e6;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.question .q-header .subject {
  padding: 10px;
  background-color: #dce1e6;
  width: 100%;
  margin-bottom: 10px;
  margin-left: 0;
  margin-right: 0;
}

.question .q-header .infos div {
  display: inline-block;
  margin-right: 10px;
}

.question .q-content {
  font-weight: normal;
}

.question .q-options {
  margin-bottom: 0px;
  padding-bottom: 30px;
}

.question .q-options .option {
  display: flex;
}

.question .q-options .option .letter {
  margin-right: 10px;
}

.question .q-footer {
  border-top: 2px solid #dce1e6;
}

.question .q-footer .button {
  background: none;
  color: #202C45;
  font-size: 0.9em;
  padding: 0;
  padding: 15px 20px;
  margin-bottom: 0;
  border-radius: 0;
  border-top: 2px solid #dce1e6;
  margin-top: -2px;
}

.question .q-footer .button:hover {
  background-color: #dce1e6;
}

.question .q-footer .active {
  background-color: #f1f3f5;
  border-top: 2px solid #2087F5;
}

.question .q-footer .active:hover {
  background-color: #f1f3f5;
}

.question .q-footer .tab {
  padding: 45px 20px 20px;
  background-color: #f1f3f5;
  position: relative;
}

.question .q-footer .tab .close {
  position: absolute;
  right: 20px;
  top: 15px;
  cursor: pointer;
}

.ql-container {
  background-color: white;
}

.comment {
  padding: 25px;
  background-color: white;
  margin-bottom: 20px;
}

.comment .data {
  font-size: 0.9em;
  margin-bottom: 20px;
}

.comment .name {
  font-weight: bold;
  margin-bottom: 20px;
}

.ck.ck-editor__main > .ck-editor__editable {
  padding: 20px;
}

.premium h1 {
  font-size: 2.3em;
}

.problem {
  background-color: #f0f4f7;
  padding: 0;
  margin-bottom: 20px;
}

.problem .questionId {
  background-color: #dce1e6;
  margin-bottom: 0;
  padding: 5px 10px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.problem .questionId .by {
  font-size: 0.8em;
  font-weight: normal;
}

.problem .description {
  margin-top: 10px;
  padding: 10px;
}

.problem .actions {
  padding: 10px;
}

.howbar {
  background-color: #2087F515;
}

.howbar .item h1 {
  margin-top: 20px !important;
}

@media (min-width: 992px) {
  .howbar {
    margin-top: 100px;
    height: 240px;
  }
  .howbar .content {
    transform: translateY(-70px);
  }
}

@media (max-width: 991px) {
  .howbar .content {
    padding: 20px 0;
  }
  .howbar .content .item {
    margin-bottom: 40px;
    padding: 0 10px;
  }
}

.features {
  margin-top: 10px;
}

.features .feature {
  display: flex !important;
  align-items: flex-start !important;
  font-size: 0.85em;
}

.features .feature .icon i {
  font-size: 3em;
}

.features .feature .infos {
  text-align: left;
  margin-left: 20px;
}

.features .feature .infos h1 {
  margin-bottom: 0;
}
/*# sourceMappingURL=styles.css.map */
